body {
  background-color: #f1f1f1;
  font-size: 18px;
}

// Override Bootstrap
.list-group-item > .badge {
  margin-left: 1em;
}

header {
  text-align: center;
  margin-bottom: 20px;

  h1 {
    font-family: Damion;
    margin-bottom: 10px;
  }

  nav {
    &.navs-main {
      margin-bottom: 5px;
    }

    &.navs-social {
      font-size: 1.2em;
      margin-bottom: 10px;
    }

    a {
      color: #888888;
      text-decoration: none;
      margin: 0 3px;

      &:hover, &:active {
        text-decoration: none;
      }
    }
  }
}

.outer {
  margin: 0 auto;
  max-width: 780px;
  margin: 10px auto;
  min-width: 320px;
}

.article  {
  .panel-body {
    img {
      max-width: 100%;
    }

    p:first-child {
      img {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
}

.page-article {
  .article-title {
    font-size: 1.5em;
    line-height: 1.5em;
    color: #555;
  }
}

/* Article Styles */

.panel-title + .article-meta {
  margin-top: 10px;
}

.meta {
  margin-right: 10px;
  display: inline;
  font-size: 0.8em;
  color: #999999;

  &.meta-comment {
    margin-right: 0;
  }

  &.meta-tags a:not(:last-child):after {
    content: ', ';
  }

  a {
    color: #999999 !important;
    text-decoration: none;
  }
}

.panel-body {
  word-wrap: break-word;
}

.panel-tags .panel-body {
  word-break: normal;
}

.article-content {
  h1, h2 {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 5px;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.1em;
  }
}

.article-cover {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  margin-bottom: 15px;
  max-height: 300px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.article-summary * {
  font-weight: normal !important;
  font-size: 1em !important;
}

.article-tags {
  .tag {
    font-size: .8em;
    color: #999;
    text-decoration: none;

    &:before {
      content: '#';
      color: #ccc;
    }
  }
}

/* Pagination */
.paginator {
  text-align: center;
}

/* Footer */
body > footer {
  color: #888888;
  font-size: 0.8em;
  background-color: #e8e8e8;
  text-align: center;
  margin-top: 30px;
  padding: 10px 20px;

  a, a:hover {
    color: #888888;
    text-decoration: underline;
  }
}

/* Tag Cloud */
#tags { list-style: none; margin: 0; padding: 0; text-align: center;}
#tags a { text-decoration: none; }
#tags li { display: inline; margin-right: 20px; }
#tags .tag-3 { font-size: 1.9em; line-height: 1.9em; }
#tags .tag-2 { font-size: 1.6em; line-height: 1.6em; }
#tags .tag-1 { font-size: 1.3em; line-height: 1.3em; }
#tags .tag-0 { font-size: 1.0em; line-height: 1.0em; }

#archives dt h3 { border-bottom: 1px solid #eeeeee; margin-bottom: 5px; }
#archives a { text-decoration: none; }
#archives dd span { float: right; }
#archives dd { margin: 0; }
#archives dd:hover { background: #eeeeee; }


// Override hljs
.hljs {
  background-color: inherit;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
  .article > section {
    padding: 5px;
  }

  .article-title {
    font-size: 1.2em;
    line-height: 1.5em;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .article-title + .article-meta {
    margin-top: 2px;
  }
}

.row .article .panel-title {
  font-size: 1.2em;
  line-height: 1.5em;

  a {
    vertical-align: middle;
  }
}